import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { apiUrl} from'../Config/Config';
import axios from 'axios';
import $ from 'jquery';
import { lang } from '../Helpers/lang';
import Header from '../Layouts/Header';
// import BootstrapTable from 'react-bootstrap-table-next';
// import cellEditFactory from 'react-bootstrap-table2-editor';
import parser from  'html-react-parser';

import moment from 'moment';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";

import MenuSidebar from '../Layouts/MenuSidebar';
import { baseUrl } from '../Config/Config';

import { useParams, useNavigate } from 'react-router-dom';
const withRouter = WrappedComponent => props => {
  const params = useParams();
  const  navigate = useNavigate();
  return (
    <WrappedComponent
    {...props}
    params={params}
    navigate ={navigate}
    // etc...
    />
  );
  };
class Report extends Component {
  
  constructor(props)
  {
     super(props); 
     this.inputRef = React.createRef();
      this.state = {
          reportlist:'',
          activePage: 1,
          totalRecord: '',
          search_all:'',
      };
      
    
      this.handleInputChange = this.handleInputChange.bind(this);
      this.handleFormSubmit = this.handleFormSubmit.bind(this);
     
    }

   
   componentDidMount() {
    this.listloadData()
   
   }
   
listloadData(postobject=''){
  var qs = require('qs');
        
        
            let admin_id = localStorage.getItem("admin_id")
        
    if(postobject!=''){
      var postobject=postobject;
    }else{
      var postobject = {
        activePage: 1,
        search_all: '',       
        admin_id: admin_id,
        };  
    }
        axios.post(apiUrl+"raisedispute/reportlist",qs.stringify(postobject)).then(res => {
            console.log(res.data,'data')
            if(res.data.status === 'success'){	
              this.setState({  reportlist: res.data.raisedisputelist, totalRecord: res.data.records_count ,}, function() {
                 this.raisedispute();
                });
            } else {
               this.setState({  reportlist: [],}, function() {
                this.raisedispute();
             });
            }
          }); 

}


raisedispute() {
  var reportlist = this.state.reportlist;
  

  if (reportlist !== undefined && reportlist !== null) {

      if (Object.keys(reportlist).length > 0) {
        const userlistDetails = reportlist?.map((reportlist, Index) => {
          let sno = Index + 1;
          

          return (
            <tr key={reportlist.id}>
              <td>{sno}</td>
              <td>{reportlist.firstname}</td>

              <td>{reportlist.dispute}</td>
              <td>{reportlist.dispute_message}</td>
              
              <td>{ moment(reportlist.created_on).format("YYYY-MM-DD hh:mm A")}</td>
              
             
            </tr>
          );
        });
        return userlistDetails;
      } else {
        return (
          <tr>
            <td colSpan="9" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
   
  } else {
    return (
      <tr>
        <td colSpan="11" className="v-align-nr">
          No Result
        </td>
      </tr>
    );
  }
}
 
  handlePageChange(pageNumber) {
    this.setState({activePage: pageNumber});
    var qs = require('qs');
    var postobject = {
      activePage: pageNumber,
      search_all: this.state.search_all,
      admin_id: localStorage.getItem('admin_id')  
    };
   this.listloadData(postobject)
  }

  handleInputChange(event) {
  const {name, value} = event.target;  
  const postObject = {
    activePage: value ? 1 : this.state.activePage,  
    ...(value && { search_all: value }),  
    admin_id: localStorage.getItem('admin_id')
  };    
  this.setState({
    [name]: value
  },function(){
    this.listloadData(postObject)
  });
    

}
 handleFormSubmit = () => {
      
        this.setState({Loading:true});
        var qs = require('qs');
        const formPayload = this.state;
         
        var postObject = {             
          activePage   : this.state.activePage,
          search_all : this.state.search_all,
          admin_id: localStorage.getItem('admin_id')           
        };
        this.listloadData(postObject)
      
  } 

   componentWillReceiveProps(Props){
   }

  
  render()  {
   
   
       


    return (
      <div className="wrapper"> 
   
    <Header />
    <MenuSidebar currentpage="disputereport" />  
  <div className="content"> 
    <div className="content-wrapper">
    <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">
    <div className="success_message"></div>
        <div className="listing-header"> 
          <div className="title">

          <h3>{this.state.totalRecord > 0?this.state.totalRecord:''}  Dispute Report {this.state.totalRecord>1 ? '' : ''}</h3> 
          </div>
            <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
          </form>
                </div>
          {/* <div className="add_button">   */}
             {/* <a className="" href='/raisedispute-add'>Add &nbsp;&nbsp;</a> */}
           {/* <Link onClick={this.exportdata.bind(this)} className="" title="Export"> Export <i className="export-icon icon" aria-hidden="true"></i></Link> */}
          {/* </div> */}
        </div>

        <div className="car-listing-row">
          
        <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>User</th>
                <th>Dispute Reason </th>
                <th>Message</th>
                <th>Report On</th>
               
              </tr>
            </thead>
            <tbody>{this.raisedispute()}</tbody>
          </Table>
            <div className="text-center">
              <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={10}
              totalItemsCount={this.state.totalRecord}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange.bind(this)}
              />
            </div>
        </div>
                      
      </div>  
    </div>  
  </div>
    
    </div>
    );
  }
}

export default Report;