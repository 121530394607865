import React, { Component } from 'react';
// import { Link, withRouter } from "react-router-dom";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { scrollToTopValidate,PageTitle } from "../Helpers/SettingHelper";
import { apiUrl,doctorURL} from'../Config/Config';
import axios from 'axios';


import { useParams, useNavigate} from 'react-router-dom';
const withRouter = WrappedComponent => props => {
  const params = useParams();
  const  navigate = useNavigate();
  return (
  <WrappedComponent
  {...props}
  params={params}
  navigate ={navigate}
  />
  );
};

class View extends Component {
  
  constructor(props)
  {
      super(props); 
      var RolesId = (typeof this.props.params.ID === 'undefined') ? '' :this.props.params.ID;
      this.state = {
          rolesId:RolesId,
          roles:'',
          selectedmodules:'',
          rolelist:'',
      };
      var qs = require('qs');
      var rolesId = this.state.rolesId;
      axios.get(apiUrl+"Rolesmanagement/getroledetail?id="+rolesId).then(res => {
        this.setState({
          roles:res.data.list,
          selectedmodules:res.data.module,
        });
      }); 
  }

  componentDidMount() {
    document.title = PageTitle('Roles View');
  }

  modulelist(){
    var list = this.state.selectedmodules;
    
    if (list != "undefined" && list != null) {
      if (list.length > 0) {
        var count = Object.keys(list).length ;
        if (Object.keys(list).length > 0) {
          const helperlistDetails = list.map(
            (data, Index) => {
              if(data != "undefined" && data != null && data != '')
              {
                if(count>++Index)
                {
                  return (
                    <>{data.label}, </>
                  );
                }else{
                  return (
                    <>{data.label}. </>
                  );
                }
                
              }
            }
          );
          return helperlistDetails;
        }
      } else {
        return (
          <span> No Result</span>
        );
      }
    }else {
      return (
        <span> No Result </span>
      );
    }
  }

  render() {

    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="shulerolemanagement" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
               <div>
                  <a className="cursor-pointer btn-arrow" href="/shulerolemanagement">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800.48 613.46"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(-1 0 0 -1 800.48 613.46)"><g xmlns="http://www.w3.org/2000/svg" transform="matrix(0 -1 1 0 -199.77 906.72003)"><path d="m857.14 640.38-221.02 225.27-4.25-665.88-65.172 1.4141-1.418 667.3-223.85-226.68-48.172 48.172 306.02 310.27 307.44-311.69z" fillRule="evenodd"/></g></g></svg>
                  </a>
                </div>
                <div className="title">
                  <h3>Roles Details</h3>
                </div>
                <div className="form-row">
                    <div className="details_left">
                       
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>User Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.roles.username}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b> Name<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.roles.shules_name} </p>
                          </div>
                        </div>
                        
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Email<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.roles.email}</p>
                          </div>
                        </div>
                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Contact number<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.roles.mobileno}</p>
                          </div>                          
                        </div>

                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Roles<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.state.roles.role_name}</p>
                          </div>                          
                        </div>


                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Modules<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                              <p>{this.modulelist()}</p>
                          </div>                          
                        </div>


                        <div className="view-details">
                          <div className="view-details-one">
                              <p><b>Status<span>:</span> </b></p>
                          </div>
                          <div className="view-details-two">
                            <p>{(this.state.roles.status=='A')?'Active':'Inactive'}</p>
                          </div>                          
                        </div>


                    </div>
                  </div>
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

export default (withRouter(View));